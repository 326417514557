import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const Card = styled.div`
  border-radius: 0rem;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.15);
  margin-bottom: 62px;
  .is-size-6,
  .is-size-5 {
    line-height: 30px !important;
  }
`;

const Features = ({ data }) => {
  return (
    <div className="section">
      <div className="container">
        <div className="columns">
          {data.homeFeatures.map((item) => (
            <div key={item._key} className="column is-flex">
              <Card className="card p-4">
                <div className="card-image is-flex is-justify-content-center mb-5">
                  <figure className="image is-128x128">
                    <Img
                      fluid={item.image ? item.image.asset.fluid : ' '}
                      alt={item.title ? item.title : 'Features'}
                    />
                  </figure>
                </div>
                <h3 className="has-text-centered is-family-primary has-text-weight-bold is-size-5 mb-5 is-spaced has-text-black">
                  {item.title}
                </h3>
                <p className="has-text-centered is-size-6 line-height">
                  {item.subtitle}
                </p>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Features;
